import React from "react";
import { useState } from "react";
// import Button from '../ui-components/button/button';
import "./blogBox.scss";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { Button, Col, Container, FormControl, ModalBody, Row } from "react-bootstrap";
import Contactmini from "../contact/contact-mini";


function Blog(props){
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  }
  return(
    <div className="blog__box">
      <div className="blog__image">
        <img src={props.image} alt="blog story" />
        <div className="blog__hover flex-center" onClick={handleShow}>
          <h4 className="font30 weight800">Get In Touch</h4>
        </div>
      </div>
      <div>
        <br/>
          <h4 className="font15 weight800">{props.title}</h4>
          <p className="font12 weight500 padding10">{props.description}</p>
          <br/>
          {/* <Button onClick={handleShow}
          className="font14 blog__button"

          >Get In Touch</Button> */}
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header 
            style={{
               backgroundColor: '#131068',
            }}
        closeButton>
          <Modal.Title
                style={{
                  color: '#fff',
                }}
          >Enquiry Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Contactmini service={props.title}/>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Blog;
