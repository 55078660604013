import React from "react";
import { Row, Col } from "react-flexbox-grid";
// SCSS
import "./hero.scss";
//Assets
import HeroImage from '../../assets/hero/hero-img3.jpg';
//Components
import Button from '../ui-components/button/button';

const hero = () => (
  <div className="hero" id="hero">
    <div className="wrapper">
      <Row>
        <Col md={12} lg={6}>
          <div className="hero-info">
            <p style={{
                display:'none'
            }}>
              VCSS UK
            </p>
            <p style={{
                display:'none'
            }}>
              V CONNECT
              V Connect
              The V Connect
              V connect security services
              V connect security
              V connect security services uk
              V connect security services united kingdom
              V connect security services website
              vcss.uk
            </p>
            <h1 className="weight800 font60 mColor">
              Empowering <span style={{color:"#cda046"}}>
              UK's
                </span> Workforce 
            </h1>
            <p className="font12">
            The V Connect, a leading provider of job opportunities across the United Kingdom. Our mission is to connect job seekers with rewarding employment opportunities in a variety of sectors, including security services, Staffing/recruiting services, events, and many others.
            </p>
            {/* <Button label="SEND MESSAGE" target={"contact"}/> */}
          </div>
        </Col>
        <Col md={12} lg={6}>
          <div className="hero-image">
            <img src={HeroImage} alt="hero" style={{
              borderRadius: '15px',
              marginTop: '30px'
            }}/>
          </div>
        </Col>
      </Row>
    </div>
  </div>
);

export default hero;
