import React from "react";
import { Row, Col } from "react-flexbox-grid";
import "./about.scss";
// Components
import TeamBox from './teamBox';
import TeamInfo from "./teamInfo";
import Title from "../ui-components/title/title";
// Assets
import Person01 from "../../assets/about/person01.png";
import Person02 from "../../assets/about/person02.png";
import About from "../../assets/about/about.jpg";

const about = () => (
  <div id="about">
    <div className="wrapper">
      <Title title="About Us" />
      <p className="font30 weight800 mColor">
          VCSS - V Connect Security Services
      </p>
      <Row style={{
        marginTop: '50px',
      }}>
        <Col md={12} lg={6}>
          <img src={About} style={{
            borderRadius: '15px',
          }}/>
          
        </Col>
        <Col md={12} lg={6}>
          <p className='para'>
            <br/>
          At VCSS, we are a leading security services company dedicated to providing top-notch protection and peace of mind. With our team of highly trained professionals, cutting-edge technology, and customized solutions, we strive to exceed our clients' expectations by delivering comprehensive security services for construction sites, events, executives, residential and commercial properties, as well as offering risk assessment, alarm response, and facility management. Our unwavering commitment to excellence and our focus on ensuring safety and security make us the trusted choice for all your security needs.
          </p>
        </Col>
      </Row>
    </div>
  </div>
);

export default about;
