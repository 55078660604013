import React from "react";
import Swiper from "react-id-swiper";
import { Row, Col } from "react-flexbox-grid";
import { useState } from "react";
// SCSS
import "./blog.scss";
import "swiper/css/swiper.css";
// Assets
import Eventsecurity from "../../assets/blog/event-security.png";
import Constructionsecurity from "../../assets/blog/construction-security.jpg";
import Alarmresponse from "../../assets/blog/alarm-response.jpg";
import Fireprotection from "../../assets/blog/fire-protection.jpg";
import Residentialsecurity from "../../assets/blog/residential-security.jpg";
import Commercialsecurity from "../../assets/blog/commercial-security.jpg";
import Mobilesecurity from "../../assets/blog/mobile-security.jpeg";
import onsitesecurity from "../../assets/blog/onsite-security.jpg";
import uniformedsecurity from "../../assets/blog/uniformed-security.jpg";
import lossprevention from "../../assets/blog/loss-prevention.jpg";
import cleaner from "../../assets/blog/cleaner.jpg";
import cctv from "../../assets/blog/cctv.jpg";
import stewards from "../../assets/blog/steward.jpg";
// Components
import Title from "../ui-components/title/title";
import BlogBox from "./blogBox";

function Blog() {
    return (
      <div className="blog" id="blog">
        <div className="wrapper">
          <Title title="Our Services" />
          <p className="font12">
          These are the services we provide to our clients. We are always open to new ideas and suggestions. If you have any, please feel free to contact us. 
          </p>
         <div className="padding30">
          {/*    <Row>
              <Col md={6} lg={6}>
                <BlogBox
                    image={Eventsecurity}
                    id="1"
                    title="Event Security"
                    description="Event security guards are responsible for the security and safety of any event. Security Officers will ensure that the guest dont distrupt the event in any way. It also provide security during the event itself, and also responsible by looking for any signs of crime, identifying trespassers, and reporting suspicious incidents."
                />
              </Col>
              <Col md={6} lg={6}>
                <BlogBox
                    image={Constructionsecurity}
                    id="2"
                    title="Construction Security"
                    description="Construction security guards are responsible to monitor the site for any signs of danger, and they can help to respond to any emergencies that may occur. They provide security services to construction sites, with fully equipped security guards and construction site tools."
                />
              </Col>
              </Row>
              <Row>
              <Col md={6} lg={6}>
                <BlogBox
                    image={lossprevention}
                    id="3"
                    title="Loss Prevention/Contractors Watch"
                    description="We provide security services 24hours a day, 7days a week with interior and exterior patrols of the site to prevent any possible theft. Our dedicated team remains vigilant, safeguarding your premises day and night."
                />
              </Col>
              <Col md={6} lg={6}>
                <BlogBox
                    image={Alarmresponse}
                    id="4"
                    title="Alarm Response"
                    description="The main key we are responsible on alarms to investigate as per the clients request within quick response time and report immediately if any suspicious activity at site."
                />
              </Col>
              </Row>
              <Row>
              <Col md={6} lg={6}>
                <BlogBox
                    image={Residentialsecurity}
                    id="5"
                    title="Residential and Retail Security"
                    description="They are responsible to provide consistently reliable service in a timely and professional manner by supplying all the equipment and skills necessary to handle just about every type of job our clients have for our officers. Our officers go above and beyond to exceed client expectations. We prioritize excellence, ensuring reliable and exceptional service every time."
                />
              </Col>
              <Col md={6} lg={6}>
                <BlogBox
                    image={Commercialsecurity}
                    id="6"
                    title="Commercial and Industrial Security"
                    description="Our security guards are at the core of everything we do. We invest time in an intensive selection process, which includes in-depth background checks followed by extensive training in first aid, fire prevention, customer service and security-specific skills. All to ensure your facilities, assets, and people have the best security possible. "
                />
              </Col>
            </Row> */}
            <Row>
              <Col md={6} lg={6}>
                <BlogBox
                    image={onsitesecurity}
                    id="7"
                    title="Security"
                    description="Are you interested in a career in security? Do you have a passion for helping others and a commitment to keeping people safe? If so, we invite you to join our network of security providers."
                />
              </Col>
              <Col md={6} lg={6}>
                <BlogBox
                    image={uniformedsecurity}
                    id="10"
                    title="SIA Training"
                    description="Looking for the best SIA training program in town? Look no further! Our comprehensive training courses are designed to prepare contractors and workers for success in the security industry."
                />
              </Col>
            </Row>
            <Row>
              <Col md={6} lg={6}>
                <BlogBox
                    image={cleaner}
                    id="9"
                    title="Cleaning"
                    description="Looking for a fulfilling career as a cleaner? Join our team of experienced and dedicated cleaners who play a critical role in maintaining safe and healthy environments. We offer exciting opportunities for individuals who have a passion for providing top-notch cleaning services."
                />
              </Col>
              <Col md={6} lg={6}>
                <BlogBox
                    image={Fireprotection}
                    id="8"
                    title="Fire Marshal"
                    description="Looking for a fulfilling career as a fire marshal? Join our team of skilled fire safety professionals and make a difference in keeping people safe from fire hazards.
                    Become a crucial part of a dedicated team . We offer exciting opportunities for individuals who are passionate about fire safety."
                />
              </Col>
            </Row>
            <Row>
              <Col md={6} lg={6}>
                <BlogBox
                    image={Alarmresponse}
                    id="9"
                    title="Alarm Response"
                    description="The main key we are responsible on alarms to investigate as per the clients request within quick response time and report immediately if any suspicious activity at site."
                />
              </Col>
              <Col md={6} lg={6}>
                <BlogBox
                    image={Residentialsecurity}
                    id="8"
                    title="Residential and Retail Security"
                    description="They are responsible to provide consistently reliable service in a timely and professional manner by supplying all the equipment and skills necessary to handle just about every type of job our clients have for our officers."
                    />
              </Col>
            </Row>
            <Row>
              <Col md={6} lg={6}>
                <BlogBox
                    image={cctv}
                    id="9"
                    title="CCTV Monitoring"
                    description="Are you ready to embark on a rewarding career as a steward? Become a valued member of our experienced stewarding team, where you'll play a crucial role in ensuring seamless operations and guest satisfaction. We're seeking individuals who are passionate about upholding cleanliness and orderliness to create exceptional environments."
                />
              </Col>
              <Col md={6} lg={6}>
              <BlogBox
                    image={stewards}
                    id="9"
                    title="Stewards"
                    description="If you're passionate about maintaining safety and security, consider joining our team as a CCTV Monitoring Specialist. Your keen eye and attention to detail will be essential in upholding a secure atmosphere. Be part of a dynamic team that's dedicated to creating a safe environment for all. Exciting opportunities await those who are enthusiastic about top-notch security services."
                />
              </Col>
            </Row>
            <Row>
              <Col md={6} lg={6}>
                
                  <BlogBox
                    image={Eventsecurity}
                    id="8"
                    title="Event Security"
                    description="Event security guards are responsible for the security and safety of any event. Security Officers will ensure that the guest dont distrupt the event in any way. It also provide security during the event itself, and also responsible by looking for any signs of crime, identifying trespassers, and reporting suspicious incidents."
                />
              </Col>
              <Col md={6} lg={6}>
                <BlogBox
                    image={Constructionsecurity}
                    id="8"
                    title="Construction Security"
                    description="Construction security guards are responsible to monitor the site for any signs of danger, and they can help to respond to any emergencies that may occur. They provide security services to construction sites, with fully equipped security guards and construction site tools."
                />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }

export default Blog;
