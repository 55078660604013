import React from "react";
import { Row, Col } from "react-flexbox-grid";
import { Link } from "react-scroll";
import "./footer.scss";

import Logo from '../../assets/footer/VCSS_Logo_f.png';
import Arrow from '../../assets/footer/arrow.svg';

const partnerBox = () => (
  <div className="footer">
    <div className="wrapper">
      <Row >
        <Col xs={12} sm={4} md={4} >
          <div>
            <img src={Logo} alt="logo" style={{
              marginLeft: '23px',
              maxWidth:"20%"
            }}/>
            <p style={{marginTop:"7px"}}>
            The V Connect, a leading provider of job opportunities across the United Kingdom. Our mission is to connect job seekers with rewarding employment opportunities in a variety of sectors, including security services, Staffing/recruiting services, events, and many others.
            </p>
          </div>
        </Col>
        <Col xs={12} sm={4} md={4} style={{
          marginTop: '5px',
        }}>
          <div className="text-center">
            <h3 style={{color:"white", marginLeft:"7px"}}>Address</h3>
            <p style={{
              marginTop: '15px',
            }}>
              <span style={{color:"rgb(255, 193, 7)"}}>
              VCSS - V Connect Security Services Limited
                </span><br/>
              Company registered in England and Wales under the Company number: 14675823 <br/>
                18 Fern dells, Hatfield <br/>
                AL10 9HX, United Kingdom<br/>
              <i className="bi bi-envelope" /> {' '}{' '} <a href="mailto:info@vcss.uk" className="whiteColor" style={{ textDecoration: "none"}}>info@vcss.uk</a><br/>
                    <i className="bi bi-telephone" /> {' '}{' '} 
                    <a href="tel:+447864512999" className="whiteColor" style={{ textDecoration: "none"}}>+44 7864512999</a>
                    <br/>
            </p>
          </div>
        </Col>
        <Col xs={12} sm={4} md={4} style={{
          marginTop: '5px',
        }}>
          <div className="text-center">
            <h3 style={{color:"white"}}>Quick Links</h3>
            <ul style={{marginTop:"15px"}}>
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="/terms-and-conditions">Terms & Conditions</a>
              </li>
              <li>
                <a href="/privacy-policy">Privacy Policy</a>
              </li>
              <li>
                <a href="/disclaimer">Disclaimer</a>
              </li>
            </ul>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={6} md={6}>
          <div className="footer-box">
            <p>© 2023 - <span  style={{
              color: '#FFC107',
            }}>VCSS - V Connect Security Services Limited.</span> All Right Reserved</p>
          </div>
        </Col>
        <Col xs={12} sm={6} md={6}>
          <Link to="hero" spy={true} smooth={true} offset={0} duration={500}>
            <div className="footer-box back-to-top">
              <p>BACK TO TOP</p>
              <img src={Arrow} alt="arrow" />
            </div>
          </Link>
        </Col>
      </Row>
    </div>
  </div>
);
export default partnerBox;
